.quality-inspection-list-page .quality-inspection-list-page-card {
  margin-top: 20px;
}
.quality-inspection-list-page .ant-card-body {
  padding: 20px;
}
.quality-inspection-list-page .ant-card-body .ant-tabs-nav {
  margin-bottom: 0;
}
.quality-inspection-list-page .key-word-tag {
  margin: 5px 16px 5px 0;
  padding: 2px 7px;
  color: #000;
  border-radius: 20px;
}
.quality-inspection-list-page .key-word-tag .anticon {
  color: #000;
}
.quality-inspection-list-page .ant-table-pagination.ant-pagination {
  margin: 20px 0 0;
}
.quality-inspection-list-page .quality-inspection-option-page .ant-table .mark-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quality-inspection-list-page .quality-inspection-option-page .ant-table .mark-container .tag-container {
  flex: 1;
}
.quality-inspection-list-page .quality-inspection-option-page .ant-table .mark-container .button-container {
  width: 120px;
}
.quality-inspection-list-page .quality-inspection-option-page .ant-table .mark-container .button-container .ant-btn-link {
  padding: 4px;
}
.quality-inspection-list-page .key-word-page .key-word-item .ant-card .ant-card-head {
  padding-left: 0;
  padding-right: 0;
}
.quality-inspection-list-page .key-word-page .key-word-item .ant-card .ant-card-head .ant-card-head-title {
  padding-top: 20px;
}
.quality-inspection-list-page .key-word-page .key-word-item .ant-card .ant-card-body {
  padding: 10px 0;
}
.quality-inspection-list-page .key-word-page .key-word-item .key-word-tag {
  margin: 10px 20px 10px 0;
}
.quality-inspection-list-page .key-word-page .key-word-item .key-word-tag .anticon:hover {
  color: #1890ff;
}
.quality-inspection-list-page .key-word-page .key-word-item .ant-divider {
  margin: 0;
}
