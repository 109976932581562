.statistic {
  padding: 5px !important;
  max-width: 120px;
  cursor: pointer;
}
.statistic.inline {
  display: flex;
  justify-content: space-between;
  max-width: 520px;
  align-items: center;
}
.statistic.inline .ant-statistic-title {
  color: #000000d9;
}
.statistic:hover {
  background-color: #d5e7ff;
  border-radius: 5px;
}
.statistic.active {
  background-color: #aacfff;
  border-radius: 5px;
}
.ant-badge.indicator .ant-badge-count {
  color: #000000d9;
  background-color: #a0c5e8b0;
}
.ant-badge.indicator .ant-badge-count:hover {
  background-color: #1890ff;
}
.ant-badge.indicator.active .ant-badge-count {
  background-color: #1890ff;
}
.zero-statistic:hover {
  background-color: transparent !important;
  cursor: default !important;
}
.header-title {
  display: flex;
  align-items: center;
}
.header-title .phone-icon {
  cursor: pointer;
}
