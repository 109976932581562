.call-record-time-line-pop.ant-popover .ant-popover-content .ant-popover-inner {
  border-radius: 26px;
}
.call-record-time-line-pop.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 24px 34px 12px;
}
.call-record-time-line-pop.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .ant-timeline .ant-timeline-item {
  width: 300px;
}
.call-record-time-line-pop.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .ant-timeline .ant-timeline-item .ant-timeline-item-label {
  left: -10px;
  color: #00000073;
}
.call-record-time-line-pop.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .ant-timeline .ant-timeline-item-last {
  padding: 0;
}
.call-record-time-line-pop.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content .ant-timeline .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: unset;
}
