.quality-inspection-detail-page .ant-card-head {
  padding: 0 20px;
}
.quality-inspection-detail-page .ant-card-head .ant-card-head-title a {
  color: #000;
}
.quality-inspection-detail-page .ant-card-head .ant-card-head-title a:hover {
  color: #1890ff;
}
.quality-inspection-detail-page .ant-input-disabled,
.quality-inspection-detail-page .ant-input-number-input {
  cursor: text !important;
  color: #000 !important;
}
.quality-inspection-detail-page .ant-input-number-disabled {
  background: none !important;
  color: #000 !important;
}
.quality-inspection-detail-page .ant-card-body {
  padding: 20px;
}
.quality-inspection-detail-page .col-flex {
  display: flex;
}
.quality-inspection-detail-page .embellish-text {
  display: flex;
  align-items: center;
}
.quality-inspection-detail-page .mb24 {
  margin-bottom: 24px;
}
.quality-inspection-detail-page .ml10 {
  margin-left: 10px;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-header {
  align-items: center;
  background-color: #fff;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text .ant-form-item.meta-form-field {
  margin-bottom: 0;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-content {
  background-color: #fafafa;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .mark-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #d9d9d9;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .mark-item .input-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .mark-item .input-container .ant-form-item.meta-form-field {
  margin-bottom: 0;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .mark-item .input-container .ant-input {
  width: 200px;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .mark-item .input-container .ant-input-number {
  width: 150px;
}
.quality-inspection-detail-page .mark-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .mark-item:last-child {
  border-bottom: none;
}
.quality-inspection-detail-page .mark-collapse .ant-input,
.quality-inspection-detail-page .mark-collapse .ant-input-number {
  margin-right: 10px;
}
.quality-inspection-detail-page .mark-collapse .ant-form-item-required.ant-form-item-no-colon {
  display: none;
}
