.score-detail-page .flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.score-detail-page .required-identification {
  margin-bottom: 10px;
}
.score-detail-page .required-identification::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 14px;
  content: "*";
}
.score-detail-page .ant-card-head {
  padding: 0 20px;
}
.score-detail-page .ant-card-head-title a,
.score-detail-page .quality-inspection-scheme a {
  color: #000;
}
.score-detail-page .ant-card-head-title a:hover,
.score-detail-page .quality-inspection-scheme a:hover {
  color: #1890ff !important;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .top {
  align-items: flex-start;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .top .quality-inspection-scheme {
  flex: 1;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .top .quality-inspection-scheme .anticon-right {
  margin-left: 10px;
  font-size: 14px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .top .quality-inspection-scheme .ant-typography {
  color: unset;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .top .total-score {
  flex: 1;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .top .total-score p {
  margin-bottom: 10px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .top .total-score span {
  color: #1890ff;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list {
  margin-top: 20px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list > p.required-identification {
  margin-bottom: 20px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item {
  background-color: #fff;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item .ant-collapse-header {
  height: 56px;
  align-items: center;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text .ant-typography {
  width: calc(100% - 40px);
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item .ant-collapse-content {
  background-color: #fafafa;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .score-item {
  justify-content: space-between;
  height: 56px;
  padding: 12px 16px 12px 70px;
  border-bottom: 1px solid #d9d9d9;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .score-item .ant-typography {
  width: calc(100% - 100px);
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .score-item .ant-input-number {
  width: 60px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.left .score-list .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .score-item:last-child {
  border-bottom: none;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .audio-container {
  position: relative;
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 40px 0;
  overflow: hidden;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .audio-container .tag {
  position: absolute;
  top: 24px;
  z-index: 1;
  max-width: 76px;
  padding: 4px 10px;
  border-radius: 6px;
  background-color: #ffe5d5;
  cursor: pointer;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .audio-container .tag .ant-typography {
  color: #e3741b;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .audio-container .tag::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid transparent;
  border-top-color: #ffe5d5;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .audio-container audio {
  width: 100%;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .key-word-container {
  margin-bottom: 20px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .key-word-container p {
  margin-bottom: 10px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .key-word-container .tag-container .ant-tag {
  max-width: 100px;
  margin-bottom: 8px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .key-word-container .tag-container .ant-tag:hover {
  cursor: pointer;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .key-word-container .tag-container .ant-tag:hover span {
  color: #1890ff;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline {
  margin-bottom: 60px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline > p {
  white-space: nowrap;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .ant-timeline.ant-timeline-label {
  width: 100%;
  padding-top: 6px;
  padding-right: 20px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .ant-timeline.ant-timeline-label .ant-timeline-item .ant-timeline-item-tail {
  padding-right: 6px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition {
  width: 100%;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .session-id-container {
  display: flex;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .session-id-container .id-label {
  color: #00000073;
  word-wrap: normal;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .session-id-container .copy-text-container {
  width: calc(100% - 80px);
  margin: 0;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .session-id-container .copy-text-container .session-id {
  width: calc(100% - 20px);
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list {
  position: relative;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 20px;
  height: 400px;
  overflow: auto;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list .text-list-item {
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
  padding-top: 26px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list .text-list-item:first-child {
  margin-top: 0;
  padding-top: 0;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list .text-list-item .content {
  max-width: 50%;
  height: auto;
  margin-top: 6px;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #f1f1f1;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list .text-list-item.active .content {
  color: #1890ff;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list .text-list-item .name {
  color: #00000073;
  text-align: left;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list .text-list-item .name .time {
  font-size: 12px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list .customer-service {
  align-items: flex-end;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list .customer-service .name {
  text-align: right;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .call-timeline .offline-speech-recognition .text-list .customer-service .content {
  background-color: #c9e7ff;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .ant-skeleton .ant-skeleton-paragraph {
  margin-top: 12px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .ant-skeleton:not(.key-word) {
  margin-top: 26px;
}
.score-detail-page .ant-card-body .ant-row .ant-col.right .ant-skeleton:not(.key-word) .ant-skeleton-paragraph li {
  height: 100px;
}
.score-detail-page .ant-card-body .btn-container {
  margin-top: 40px;
  text-align: center;
}
