.phone-search-input {
  margin: 10px auto;
}
.phone-search-input > input {
  text-align: center;
}
.phone-search-input > input::-moz-placeholder {
  text-align: center !important;
}
.phone-search-input > input::placeholder {
  text-align: center !important;
}
