.customer-header-modal .customer-header-modal-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  height: 580px;
}
.customer-header-modal .customer-header-modal-body .table-container-content,
.customer-header-modal .customer-header-modal-body .checkbox-container-content {
  height: calc(580px - 10px - 20px);
  margin-top: 14px;
  border: 1px solid #cecece;
  overflow-y: auto;
}
.customer-header-modal .customer-header-modal-body .table-container-content {
  width: 240px;
}
.customer-header-modal .customer-header-modal-body .checkbox-container .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customer-header-modal .customer-header-modal-body .checkbox-container .title button {
  height: auto;
  padding: 0 12px;
  border: none;
}
.customer-header-modal .customer-header-modal-body .checkbox-container .title button.restore-default {
  border-left: 1px solid #cecece;
  padding-right: 0;
}
.customer-header-modal .customer-header-modal-body .checkbox-container .checkbox-container-content {
  flex: 1;
}
.customer-header-modal .customer-header-modal-body .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell,
.customer-header-modal .customer-header-modal-body .ant-table .ant-table-body .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 8px 16px;
  border: none;
}
.customer-header-modal .customer-header-modal-body .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell .sort-header,
.customer-header-modal .customer-header-modal-body .ant-table .ant-table-body .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell .sort-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
}
.customer-header-modal .customer-header-modal-body .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell .sort-header .anticon-close-circle,
.customer-header-modal .customer-header-modal-body .ant-table .ant-table-body .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell .sort-header .anticon-close-circle {
  color: #b5becb;
  font-size: 16px;
  transition: all 0.2s;
}
.customer-header-modal .customer-header-modal-body .ant-table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell .sort-header .anticon-close-circle:hover,
.customer-header-modal .customer-header-modal-body .ant-table .ant-table-body .ant-table-content table .ant-table-tbody .ant-table-row .ant-table-cell .sort-header .anticon-close-circle:hover {
  color: #1890ff;
}
.customer-header-modal .customer-header-modal-body .ant-row {
  padding: 16px;
}
.customer-header-modal .customer-header-modal-body .ant-row > .ant-col {
  padding-bottom: 10px;
}
.customer-header-modal .customer-header-modal-body tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff !important;
}
.customer-header-modal .customer-header-modal-body tr.drop-over-upward td {
  border-top: 2px dashed #1890ff !important;
}
